import * as React from 'react';
import * as classnames from 'classnames';
import PagingIndicators from './PagingIndicators/PagingIndicators';
import RoundButton from '../RoundButton/RoundButton';
import * as Loader from 'react-loader';
import i18n from '../../services/I18n';

const iconPrev = require('../../images/arrow-back.svg');
const iconNext = require('../../images/arrow-front.svg');

import './PagingControl.less';

interface Props {
  nextButtonIconSrc?: string;
  backButtonIconSrc?: string;
  finishButtonIconSrc?: string;

  nextButtonText?: string;
  backButtonText?: string;
  finishButtonText?: string;

  className?: string;
  headerClassName?: string;

  showTotal?: boolean;

  loading?: boolean;
  error?: string;

  nextButtonDisabled?: boolean;
  backButtonDisabled?: boolean;
  finishButtonDisabled?: boolean;

  totalPage: number;
  defaultActivePage?: number;
  activePage?: number;

  onPageChange?: (activePage: number) => void;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onFinishClicked?: () => void;

  children: (object) => React.ReactNode;
  headerComponent?: React.ReactNode;
}

interface State {
  activePage: number;
}

class PagingControl extends React.Component<Props, State> {

  static defaultProps = {
    showTotal: true,
    defaultActivePage: 1
  };

  state = {
    activePage: this.props.defaultActivePage
  };

  isActivePageControlled = () => {
    return this.props.activePage !== undefined;
  };

  getActivePage = () => {
    return this.isActivePageControlled() ? this.props.activePage : this.state.activePage;
  };

  nextPage = (step: number) => {

    // Handle activePage state internally only when activePage prop is not controlled

    if (this.isActivePageControlled()) {

      const {onPageChange} = this.props;

      if (onPageChange) {
        onPageChange(this.getActivePage() + step);
      }
    } else {

      this.setState(prev => {
        return {activePage: prev.activePage + step};
      }, () => this.props.onPageChange(this.getActivePage()));
    }
  };

  navigateNextPage = () => {
    const {totalPage, onNextPage} = this.props;

    if (this.getActivePage() < totalPage) {

      this.nextPage(1);

      if (onNextPage) {
        onNextPage();
      }
    }
  };

  navigatePreviousPage = () => {
    const {onPreviousPage} = this.props;

    if (this.getActivePage() > 1) {

      this.nextPage(-1);

      if (onPreviousPage) {
        onPreviousPage();
      }
    }
  };

  isLastPageActive = () => {
    const {totalPage} = this.props;
    return this.getActivePage() === totalPage;
  };

  isFirstPageActive = () => {
    return this.getActivePage() === 1;
  };

  renderPreviousNavigationButton = () => {

    const {backButtonIconSrc, backButtonText, loading, error, backButtonDisabled} = this.props;

    const disabled = backButtonDisabled || loading || !!error;

    const className = classnames(
      'paging-control__back-button',
      {'paging-control__back-button--hidden': this.isFirstPageActive()}
    );

    return (
      <RoundButton
        disabled={disabled}
        iconSrc={backButtonIconSrc || iconPrev}
        className={className}
        hideIconOnWeb={true}
        onClick={this.navigatePreviousPage}>
        {backButtonText || 'Back'}
      </RoundButton>
    );
  };

  renderNextNavigationButton = () => {

    const {nextButtonIconSrc, nextButtonText,
      finishButtonText,
      onFinishClicked,
      error, loading, nextButtonDisabled, finishButtonDisabled} = this.props;

    return this.isLastPageActive() ? (
      <RoundButton
        type='success'
        className='paging-control__finish-button'
        hideTextOnMobile={false}
        hideIconOnWeb={true}
        disabled={finishButtonDisabled || loading || !!error}
        onClick={onFinishClicked}>{finishButtonText || i18n.t('button.done')}</RoundButton>
    ) : (
      <RoundButton
        iconSrc={nextButtonIconSrc || iconNext}
        className='paging-control__next-button'
        hideIconOnWeb={true}
        disabled={nextButtonDisabled || loading || !!error}
        onClick={this.navigateNextPage}>
        {nextButtonText || 'Next'}
      </RoundButton>
    );
  };

  renderTotal = () => {
    const {totalPage, showTotal, error, loading} = this.props;

    return showTotal && !loading && !error ?
      (
        <p className='paging-control__total'>
          {`${i18n.t('pageCount', {page: `${this.getActivePage()} / ${totalPage}`})}`}
        </p>
      )
      : null;
  };

  renderContent = () => {
    const {error, children} = this.props;

    if (error) {
      return (<p className='paging-control__error'>{error || 'Error'}</p>);
    }

    return <div className='paging-control__content'>{children(this.getStateAndHelpers())}</div>;
  };

  renderLoading = () => {

    const {loading} = this.props;

    const options = {
      width: 3,
      radius: 8,
      color: '#2490c9' // Tutkari blue
    };

    if (loading) {
      return (<Loader className='paging-control__loader' options={options}/>);
    }
  };

  getStateAndHelpers = () => {
    return {
      activePage: this.state.activePage,
      navigateNextPage: this.navigateNextPage,
      navigatePreviousPage: this.navigatePreviousPage
    };
  };

  getHeader = () => {
    const {headerComponent, headerClassName} = this.props;

    if (headerComponent) {
      return (<div className={classnames('paging-control__header', headerClassName)}>{headerComponent}</div>);
    }
  };

  render() {

    const {className, totalPage} = this.props;
    const activePage = this.getActivePage();

    return (
      <div className={classnames('paging-control', className)}>
        {this.getHeader()}
        <PagingIndicators className='paging-control__indicator' total={totalPage} activeIndex={activePage - 1}/>
        {this.renderLoading()}
        <div className='paging-control__container'>
          {this.renderContent()}
        </div>
        {this.renderTotal()}
        <div className='paging-control__navigation-button'>
          {this.renderPreviousNavigationButton()}
          {this.renderNextNavigationButton()}
        </div>
      </div>
    );
  }
}

export default PagingControl;
