import * as React from 'react';
import {PureComponent} from 'react';
import Input from '../Input/Input';
import SelectInput from '../SelectInput/SelectInput';

import * as classNames from 'classnames';
import {generateSelectOptions, getFieldError} from '../../util/index';

import Study from '../../models/Study';
import Users from '../../models/Users';

import './StudyDetail.less';
import EditorMCE from '../EditorMCE/EditorMCE';
import DropZone from '../Dropzone/Dropzone';
import CheckboxFormInput from '../CheckboxFormInput';
import {PictureType} from '../../models/Picture';

interface Props {
  study: Study;
  users: Users;
  availableLanguages: any;
  showError?: boolean;
  hasMore?: boolean;
  loadMore?: () => any;
  onChange: (model) => any;
  t: (key, params?) => any;
}

interface State {
  studyValidateResult: any;
}

export default class StudyDetails extends PureComponent<Props, State> {

  private static readonly inputs = [
    'studyNumber',
    'studyName',
    'userIds',
    'languages',
    'studyLogo',
    'studyImage',
    'infoTitle',
    'info',
    'thankYou',
    'thankYouIneligible',
    'qualifiedInfo',
    'extraInfo',
    'footer',
    'socialSharing',
    'askForAddress'
  ];

  state = {
    studyValidateResult: undefined
  };

  componentDidUpdate(prevProps) {

    const {study} = this.props;

    if (prevProps.study !== study) {

      this.setState({studyValidateResult: study.validate()});
    }
  }

  onFieldChangeCallback = field => value => {

    const {study, onChange} = this.props;

    if (/\S/.test(value) || field === 'extraInfo') {
      onChange(study.set(field, value));
    } else {
      onChange(study.set(field, null));
    }
  };

  onImageFieldChange = field => picture => {

    const {study, onChange} = this.props;

    if (!picture) {
      onChange(study.set(field, null).set(`${field}Id`, null));
    } else {
      onChange(study.set(field, picture));
    }
  };

  createSelectInput = (field, options, multi?, placeholder?) => {

    const {study, onChange, t, showError, hasMore, loadMore} = this.props;
    const fieldError = getFieldError(field, this.state.studyValidateResult, study.error);

    return (
      <SelectInput
        key={'select-' + field}
        model={study}
        modelName='study'
        field={field}
        options={options}
        multi={multi}
        placeholder={placeholder}
        onChange={onChange}
        labelClassName='col-12 col-sm-2'
        fieldClassName='col-12 col-sm-10'
        hasError={showError && !!fieldError}
        hasMore={hasMore}
        loadMore={loadMore}
        t={t}
      />
    );
  };

  getUserLabel = user => user.getDisplayName();

  getUserOptions = () => generateSelectOptions(this.props.users.list, this.getUserLabel, 'id');

  getInputField = (field) => {
    const {study, t, showError} = this.props;
    const fieldError = getFieldError(field, this.state.studyValidateResult, study.error);
    const wrapperClassName = classNames(['col-12 col-sm-10', field]);

    return (
      <Input
        key={'input_' + field}
        label={t('studyView.' + field)}
        labelClassName='col-12 col-sm-2'
        error={fieldError}
        hasError={showError && !!fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={study.get(field)}
        wrapperClassName={wrapperClassName}
        type={field.indexOf('password') !== -1 ? 'password' : 'text'}
        t={t}
      />
    );
  };

  createImageInput = (field, minWidth, minHeight, imageText, type) => {

    const {t, study, showError} = this.props;
    const fieldError = getFieldError(field, this.state.studyValidateResult, study.error);

    return (
      <DropZone
        type={type}
        minWidth={minWidth}
        minHeight={minHeight}
        picture={study.get(field)}
        onChange={this.onImageFieldChange(field)}
        labelClassName='col-12 col-sm-2'
        fieldClassName='col-12 col-sm-10'
        label={t('studyView.' + field)}
        error={fieldError}
        hasError={!!fieldError && showError}
        zoneText={imageText}
        t={t}/>
    );
  };

  createEditorInput = (field) => {

    const {t, study, showError} = this.props;

    const value = study.get(field);
    const fieldError = getFieldError(field, this.state.studyValidateResult, study.error);

    return (
      <EditorMCE
        key={field}
        labelClassName='col-12 col-sm-2'
        fieldClassName='col-12 col-sm-10'
        onEditorChange={this.onFieldChangeCallback(field)}
        label={t('studyView.' + field)}
        initialValue={value}
        error={fieldError}
        hasError={showError && !!fieldError}
        t={t}
      />
    );
  };

  createCheckboxInput = (field) => {

    const {t, study} = this.props;
    const value = study.get(field);
    const onChange = () => this.onFieldChangeCallback(field)(!value);

    return (
      <CheckboxFormInput
        key={field}
        field={field}
        groupClassName='row checkbox'
        labelClassName='col-12 col-sm-2'
        inputClassName='col-12 col-sm-10'
        value={value}
        onChange={onChange}
        label={t('studyView.' + field)}
      />
    );
  };

  getLanguageOptions = () =>
    generateSelectOptions(this.props.availableLanguages, key => this.props.t(`language.${key}`));

  renderInputs = () => {

    const {t} = this.props;

    return StudyDetails.inputs.map(field => {

      switch (field) {
        case 'userIds':
          return this.createSelectInput(field, this.getUserOptions(), true);
        case 'languages':
          return this.createSelectInput(field, this.getLanguageOptions(), true);
        case 'studyLogo':
          return this.createImageInput(
            field,
            192,
            144,
            t('studyView.addStudyLogo'),
            PictureType.LogoImage
          );
        case 'studyImage':
          return this.createImageInput(
            field,
            752,
            320,
            t('studyView.addStudyCover'),
            PictureType.CoverImage
          );
        case 'info':
        case 'thankYou':
        case 'thankYouIneligible':
        case 'qualifiedInfo':
        case 'extraInfo':
        case 'footer':
          return this.createEditorInput(field);
        case 'socialSharing':
        case 'askForAddress':
          return this.createCheckboxInput(field);
        default:
          return this.getInputField(field);
      }
    });
  };

  render() {

    return (
      <div className='study-detail'>
        <form className='form-horizontal study-detail__form'>
          {this.renderInputs()}
        </form>
      </div>
    );
  }
}
