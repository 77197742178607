import * as React from 'react';
import {PureComponent} from 'react';
import * as classnames from 'classnames';
import ReactPhoneInput from 'react-phone-input-2';

import * as _ from 'lodash';
import * as classNames from 'classnames';

import './PhoneNumberInput.less';

const DefaultCountry = 'fi';
const PreferredCountries = ['fi', 'de', 'ie', 'nl', 'pl'];

interface Props {
  labelClassName?: string;
  isRow?: boolean;
  field: string;
  modelId: number;
  value: string;
  error: string;
  onChange: (value, country) => any;
  t: (key, params?) => any;
  disabled?: boolean;
  hasError?: boolean;
}

interface State {
  showError: boolean;
}

export default class PhoneNumberInput extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {showError: false};
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: false});

  shouldShowError = () => {
    const {error, value} = this.props;
    const {showError} = this.state;

    return error && (showError || !_.isEmpty(value) || this.props.hasError);
  };

  render() {
    const {field, modelId, disabled, value, error, onChange, t, labelClassName, isRow} = this.props;
    const showError = this.shouldShowError();
    const wrapperClassName = classNames({
      'form-group': true,
      'row': isRow,
      'has-error': showError
    });

    const inputStyle = {
      width: '100%'
    };

    const helpBlock = (showError: boolean) => {
      if (showError) {
        return (
          <div className='error'>{t(error)}</div>
        );
      }
    };

    const labelClassnames = labelClassName ? labelClassName : classnames(
      'phone-input__label',
      'col-12 col-sm-3',
      'form-label',
      {'phone-input__label--error': showError}
    );

    return (
      <div key={`phone-input-user-${modelId}`} className={wrapperClassName}>
        <label className={labelClassnames}>
          {t(field)}
        </label>
        <div>
          <ReactPhoneInput
            defaultCountry={DefaultCountry}
            preferredCountries={PreferredCountries}
            disabled={disabled}
            enableLongNumbers={true}
            value={value}
            inputStyle={inputStyle}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={onChange}
          />
          {helpBlock(showError)}
        </div>
      </div>
    );
  }
}
