import * as React from 'react';
import {useEffect} from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import * as classNames from 'classnames';
import MainNav from '../../components/MainNav/MainNav';
import SideDrawer, {useDrawer} from '../../components/SideDrawer/SideDrawer';
import Footer from '../../components/Footer/Footer';
import QuestionnaireModule from '../../modules/Questionnaire';
import Questionnaire from '../../models/Questionnaire';
import PageNotFound from '../../routes/Subject/PageNotFound';

import './NavigationLayout.less';
import Study from '../../models/Study';
import Picture, {PictureSize, PictureType} from '../../models/Picture';
import i18n from '../../services/I18n';

interface Props {
  study: Study;
  questionnaire: Questionnaire;
  questionnaireId: number;
  getQuestionnaire: (id, queryParams?) => any;
}

const NavigationLayout: React.FunctionComponent<Props> = (props) => {

  const [isDrawerOpen, toggleDrawer] = useDrawer(false);

  const {questionnaire, study} = props;

  useEffect(() => {
    if (study && study.studyName) {
      document.title = study.studyName;
    }
  }, [study]);

  useEffect(() => {
    if (questionnaire) {
      const preferredLanguage = questionnaire.getLanguageByPreference();
      if(i18n.language !== preferredLanguage) {
        i18n.changeLanguage(preferredLanguage);
      }
    }
  }, [questionnaire]);

  useEffect(() => {

    const {questionnaireId, getQuestionnaire} = props;

    const shouldFetchQuestionnaire = questionnaire.id !== questionnaireId;

    if (shouldFetchQuestionnaire && !questionnaire.isLoading) {

      getQuestionnaire(questionnaireId);
    }
  }, []);

  const getSideDrawerStateClassName = () => {

    return isDrawerOpen ? 'navigation-layout__side-drawer--open' : 'navigation-layout__side-drawer--close';
  };

  const closeDrawerIfOpen = () => {

    if (isDrawerOpen) {

      toggleDrawer();
    }
  };

  const header = questionnaire.id ? questionnaire.getTitle() : '';

  const error = questionnaire.get('error');
  if (error && error['errorCode'] === 'notFound') {
    return (
      <PageNotFound questionnaireNotFound={true}/>
    );
  }

  const {studyLogoId, footer, socialSharing} = study || {} as any;
  const studyLogoImage = studyLogoId && new Picture({id: studyLogoId, type: PictureType.LogoImage});

  return (
    <div className='navigation-layout'>
      <header className='navigation-layout__header'>
        <MainNav
          onToggleDrawer={toggleDrawer}
          header={header}
          logoUrl={studyLogoImage && studyLogoImage.getPictureURL(PictureSize.Large)}
        />
      </header>
      <div className={classNames('navigation-layout__side-drawer', getSideDrawerStateClassName())}>
        <SideDrawer onToggleDrawer={closeDrawerIfOpen} header={header}/>
      </div>
      <div className='navigation-layout__body'>
        <div className='navigation-layout__content'>
          {props.children}
        </div>
      </div>
      <Footer className='navigation-layout__footer' footerText={footer} socialSharing={socialSharing}/>
    </div>
  );
};

const mapActionToProps = {
  getQuestionnaire: QuestionnaireModule.getModel
};

const mapStateToProps = ({questionnaire, study}, ownProps) => {

  const {params: {qid}} = ownProps;
  const questionnaireId = parseInt(qid, 10);

  return {
    study,
    questionnaire,
    questionnaireId
  };
};

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(NavigationLayout));
