import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import SanitizedHTML from 'react-sanitized-html';
import StudyModule from '../../../modules/Study';
import RoundButton from '../../../components/RoundButton/RoundButton';
import Picture, {PictureSize} from '../../../models/Picture';
import StudyModel from '../../../models/Study';
import {navigate} from '../../../modules/Location';
import {composeUrlWithStudyInfo, sanitizerAllowedAttributes, sanitizerAllowedTags} from '../../../util';
import './Study.less';

interface Props extends WithNamespaces {
  questionnaireId: number;
  study: StudyModel;
  getQuestionnaireStudy: (id) => any;
  t: (key, params?) => any;
  navigate: (url: string, silent?: boolean) => any;
  isExtraInfo: boolean;
}

interface State {
}

class Study extends Component<Props, State> {

  componentDidMount() {

    const {questionnaireId, getQuestionnaireStudy} = this.props;
    if (questionnaireId) {
      getQuestionnaireStudy(questionnaireId);
    }
  }

  componentDidUpdate(prevProps) {

    const {questionnaireId, study, navigate} = this.props;
    const {study: prevStudy} = prevProps;

    if (prevStudy.isLoading && !study.isLoading) {
      navigate(this.getUrl(study, questionnaireId));
    }
  }

  getUrl = (study: StudyModel, qid: number) => {

    const {isExtraInfo} = this.props;

    return composeUrlWithStudyInfo(
      '/questionnaires',
      study,
      `${qid}${isExtraInfo ? '/extra-info' : ''}`
    );
  };

  onNextButtonClick = () => {

    const {questionnaireId, study, navigate} = this.props;

    navigate(`${this.getUrl(study, questionnaireId)}/answer`);
  };

  renderContent(content: string) {
    return (
      <div className='study-view__content'>
        <SanitizedHTML
          allowedAttributes={sanitizerAllowedAttributes}
          allowedTags={sanitizerAllowedTags}
          html={content}/>
      </div>
    );
  }

  renderExtraInfo() {

    const {study} = this.props;

    return this.renderContent(study.extraInfo);
  }

  renderInfo() {

    const {study, t} = this.props;

    const studyCoverImage = new Picture({id: study.studyImageId});

    return (
      <>
        <img className='study-view__image' src={studyCoverImage.getPictureURL(PictureSize.Medium)}/>
        <div className='study-view__title'>{study.infoTitle}</div>
        {this.renderContent(study.info)}
        <div className='study-view__button'>
          <RoundButton
            type='success'
            hideTextOnMobile={false}
            onClick={this.onNextButtonClick}>
            {t('button.go')}
          </RoundButton>
        </div>
      </>
    );
  }

  render() {

    const {study, isExtraInfo} = this.props;

    if (!study.id) {

      return null;
    }

    const renderElement = isExtraInfo ? this.renderExtraInfo() : this.renderInfo();

    return (
      <div className='study-view'>
        {renderElement}
      </div>
    );
  }
}

const mapActionToProps = {
  getQuestionnaireStudy: StudyModule.getQuestionnaireStudy,
  navigate
};

const mapStateToProps = ({study, studySite}, ownProps) => {

  const {params: {qid}, route: {path}} = ownProps;
  const questionnaireId = parseInt(qid, 10);

  return {
    study,
    studySite,
    questionnaireId,
    isExtraInfo: !!study.extraInfo && /\/extra-info(?:\/)?$/i.test(path)
  };
};

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Study));
