import * as React from 'react';
import ModelView, {BaseProps, BaseState} from '../../../components/ModelView';
import SaveButton from '../../../components/SaveButton';

import {withNamespaces} from 'react-i18next';
import {withRouter} from 'react-router';
import {PaginationContext} from '../../../models/Pagination';
import {List} from 'immutable';
import {connect} from 'react-redux';
import {navigate, navigateBack} from '../../../modules/Location';
import QuestionnaireModule from '../../../modules/Questionnaire';
import StudyModule from '../../../modules/Study';
import StudiesModule from '../../../modules/Studies';
import StudySitesModule from '../../../modules/StudySites';

import Question from '../../../models/Question';
import Questionnaire from '../../../models/Questionnaire';
import User from '../../../models/User';
import StudyModel from '../../../models/Study';
import StudiesModel from '../../../models/Studies';
import StudySitesModel from '../../../models/StudySites';

import './Questionnaires.less';
import Tabs from '../../../components/Tabs';
import QuestionnaireFormRules from './QuestionnaireFormRules';
import QuestionnaireTranslations from './QuestionnaireTranslations';
import QuestionnaireForm from './QuestionnaireForm';
import {stringify} from 'querystring';
import * as _ from 'lodash';

interface Props extends BaseProps<Questionnaire> {
  authenticatedUser: User;
  date: Date;
  page: number;
  duplicated: boolean;
  getStudies: any;
  getStudySites: any;
  getQuestionnaireStudy: any;
  study: StudyModel;
  studies: StudiesModel;
  studySites: StudySitesModel;
  location: any;
  tab: string;
  studyPagination: any;
  studySitePagination: any;
}

interface State extends BaseState<Questionnaire> {
  selectedStudy: StudyModel;
  showError: boolean;
  pageQuestions: List<Question>;
  validationErrors: any;
}

const formId = 'form';
const formRulesId = 'form-rules';
const translationsId = 'translations';

const tabsIds = [formId, formRulesId, translationsId];

class QuestionnaireView extends ModelView<Questionnaire, Props, State> {

  constructor(props) {
    super(props);

    const validationErrors = !this.props.model.validate();

    this.state = {

      ...this.state,
      selectedStudy: this.props.study,
      showError: false,
      validationErrors,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    const {
      modelId,
      duplicated,
      getStudies,
      getQuestionnaireStudy
    } = this.props;

    if (modelId) {
      // Update questionnaire model
      getQuestionnaireStudy(modelId);
      if (duplicated) {
        // Duplicate questionnaire model. Get study sites for study selection
        getStudies();
      }
    } else {
      // Create questionnaire model
      getStudies();
    }
  }

  loadMoreStudies = () => {
    const {getStudies, studies, studyPagination, modelId, getQuestionnaireStudy, duplicated} = this.props;
    if (!studies.isLoading && studyPagination.hasMore) {
      if (modelId) {
        getQuestionnaireStudy(modelId);
        if (duplicated) {
          getStudies();
        }
      } else {
        getStudies();
      }
    }
  };

  loadMoreStudySites = () => {
    const {getStudySites, studySites, studySitePagination} = this.props;
    const {selectedStudy} = this.state;

    if (!studySites.isLoading && selectedStudy && selectedStudy.id && studySitePagination.hasMore) {
      getStudySites({studyId: selectedStudy.id});
    }
  };

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const {duplicated, study, page, getStudySites} = this.props;
    const {model, showError, selectedStudy} = this.state;

    if (study && study.id && prevProps.study.id !== study.id) {
      this.setState({selectedStudy: study});
    }

    if (selectedStudy !== prevState.selectedStudy) {
      getStudySites({studyId: selectedStudy.id});
    }

    if (showError && model.isValid()) {
      this.setState({showError: false});
    }

    if (prevState.model.isSaving && !model.isSaving) {
      this.onModelSaved();
    }

    if (duplicated && model.id) {
      this.updateModel(model.set('id', undefined));
    }

    const getValidationErrors = () => {

      if (prevState.model !== model || prevProps.page !== page) {

        return model.validate();
      }

      return this.state.validationErrors;
    };
    const validationErrors = getValidationErrors();
    if (this.state.validationErrors !== validationErrors) {
      this.setState({validationErrors});
    }

    if (showError && !validationErrors) {
      this.setState({showError: false});
    }

  }

  handleQuestionnaireStudyChange = (selectedStudy: StudyModel) => {
    this.setState({selectedStudy});
  };

  /**
   * @override
   */
  updateModel = model => this.setState({model: model.cleanupTranslations()});

  getActiveTab = () => {
    const {tab} = this.props;

    return tab ? tab : tabsIds[0];
  };

  onTabClick = (tab, event) => {

    event.preventDefault();

    const {navigate, modelId, page} = this.props;
    const target = modelId ? modelId : 'new';
    const query = stringify({tab, page});

    navigate(`/admin/questionnaires/${target}?${query}`);
  };

  getTabComponent = activeTab => {

    const {
      authenticatedUser,
      duplicated,
      studies,
      studySites,
      page,
      location,
      navigate,
      modelName,
      studyPagination,
      studySitePagination,
      t
    } = this.props;

    const {selectedStudy} = this.state;

    const {model, validationErrors, showError} = this.state;

    switch (activeTab) {
      case formId:
        return (<QuestionnaireForm
          authenticatedUser={authenticatedUser}
          page={page}
          duplicated={duplicated}
          studies={studies}
          studySites={studySites}
          study={selectedStudy}
          onStudyChange={this.handleQuestionnaireStudyChange}
          updateModel={this.updateModel}
          location={location}
          navigate={navigate}
          model={model}
          modelName={modelName}
          validationErrors={validationErrors}
          showError={showError}
          hasMoreStudies={studyPagination.hasMore}
          hasMoreStudySites={studySitePagination.hasMore}
          loadMoreStudies={this.loadMoreStudies}
          loadMoreStudySites={this.loadMoreStudySites}
          t={t}
        />);
      case formRulesId:
        return (<QuestionnaireFormRules
          authenticatedUser={authenticatedUser}
          model={model}
          updateModel={this.updateModel}
          t={t}
          page={page}
          location={location}
          navigate={navigate}
        />);
      case translationsId:
        return (<QuestionnaireTranslations
          updateModel={this.updateModel}
          model={model}
          study={selectedStudy}
          t={t}
        />);
      default:
        return null;
    }
  };

  getTabIdsWithError = () => {

    const {model, validationErrors, showError} = this.state;

    if (!showError || _.isEmpty(validationErrors)) {
      return undefined;
    }

    const additionalLanguageCodes = model.getAdditionalLanguages()
      .filter(l => l.isPublished())
      .map(l => l.language);

    const publishedAdditionalLanguageError = Object.keys(validationErrors)
      .some(errorKey => {

        const testErrorKey = (lang) => new RegExp(`.*\.${lang}(\..*|$)`).test(errorKey);

        return additionalLanguageCodes.some(testErrorKey);
      });

    return publishedAdditionalLanguageError ? [translationsId] : undefined;
  };

  getTabContent = () => {

    const activeTab = this.getActiveTab();

    return (
      <Tabs
        key={'tab-' + activeTab}
        activeTab={activeTab}
        onTabClick={this.onTabClick}
        context='questionnaire.tabs'
        tabIds={tabsIds}
        tabIdsWithError={this.getTabIdsWithError()}>
        {this.getTabComponent(activeTab)}
      </Tabs>
    );
  };

  onModelSaved = () => {
    const {navigate, duplicated, editUrl} = this.props;
    const {model} = this.state;

    // In duplicate mode, update url to have new questionnaire id, otherwise, propagate call to parent.
    if (duplicated && model.id && editUrl) {
      navigate(`${editUrl}/${model.id}`);
      return;
    } else {
      super.onModelSaved();
    }
  };

  hasErrors = () => !!this.state.validationErrors;

  onSaveQuestionnaire = () => {
    if (this.hasErrors()) {
      this.setState({
        showError: true
      });
    }
    this.save();
  };

  getSaveButton() {

    const {model} = this.state;
    const {t} = this.props;

    if (!model.studyId) {

      return null;
    }

    return (
      <SaveButton
        key='save-button'
        model={model}
        save={this.onSaveQuestionnaire}
        onModelSaved={this.onModelSaved}
        t={t}
      />
    );
  }

  getContent() {

    return (
      <React.Fragment>
        {this.getTabContent()}
        {this.getSaveButton()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  {authenticatedUser, questionnaire, questionnaires, study, studies, studySites, pagination},
  ownProps
) => {
  const {location: {query: {page, duplicate, id, tab}}, params: {qid}} = ownProps;
  const duplicated = duplicate ? !!duplicate : false;
  const questionnaireId = qid ? parseInt(qid, 10) : duplicated ? parseInt(id, 10) : undefined;
  const currentPage = page ? parseInt(page, 10) : 1;

  const navigateBackOnSaved = false;
  const backHref = '/admin/questionnaires';
  const editUrl = '/admin/questionnaires';

  const resolveModel = () => {

    if (questionnaire.id === questionnaireId || !questionnaireId) {
      return questionnaire;
    }

    const model = questionnaires.getModelById(questionnaireId);

    return model ? model : new Questionnaire();
  };

  return {
    study,
    studies,
    studySites,
    authenticatedUser,
    modelId: questionnaireId,
    model: resolveModel(),
    page: currentPage,
    duplicated,
    wrapperClass: 'questionnaire-management-view',
    modelName: 'questionnaire',
    backHref,
    editUrl,
    navigateBackOnSaved,
    tab,
    confirmUnsavedEntries: true,
    studySitePagination: pagination.getPagination(PaginationContext.STUDY_SITE),
    studyPagination: pagination.getPagination(PaginationContext.STUDY)
  };
};

const mapActionToProps = {
  getModel: QuestionnaireModule.getModel,
  resetModel: QuestionnaireModule.resetModel,
  saveModel: QuestionnaireModule.saveModel,
  navigate,
  navigateBack,
  getStudies: StudiesModule.getModels,
  getStudySites: StudySitesModule.getModels,
  getQuestionnaireStudy: StudyModule.getQuestionnaireStudy
};

export default withNamespaces(['common'], {wait: true})(withRouter(connect(
  mapStateToProps,
  mapActionToProps
)(QuestionnaireView)));
