/**
 * Created by Mauritz Untamala on 20/02/16.
 */
import * as React from 'react';
import {Component} from 'react';

import * as _ from 'lodash';
import * as classNames from 'classnames';
import {WithNamespaces, withNamespaces} from 'react-i18next';

import './Tabs.less';

interface Props extends WithNamespaces {
  context: string;
  tabIds: string[];
  tabIdsWithError?: string[];
  activeTab: string;
  onTabClick: (tab, event) => any;
}

interface State {}

class Tabs extends Component<Props, State> {

  hasTabError = tab => _.includes(this.props.tabIdsWithError, tab);

  isActiveTab = tab => this.props.activeTab === tab;

  createTabs = () => {
    return _.map(this.props.tabIds, tab => {
      const navItemClasses = classNames({
        'tabs__nav-item--active': this.isActiveTab(tab),
        'tabs__nav-item': true,
        'has-error': this.hasTabError(tab)
      });

      const navLinkClasses = classNames({
        active: this.isActiveTab(tab),
        'tabs__nav-link': true
      });

      return (
        <li key={tab} className={navItemClasses}>
          <a data-toggle='tab' className={navLinkClasses} onClick={this.props.onTabClick.bind(null, tab)}>
            {this.props.t(this.props.context + '.' + tab)}
          </a>
        </li>
      );
    });
  };

  render() {
    return (
      <div className='tabs'>
        <ul className='tabs__nav'>{this.createTabs()}</ul>
        <div id={this.props.activeTab} className='tabs__container'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withNamespaces(['common'], {wait: true})(Tabs);
