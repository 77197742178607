/**
 * Created by Mauritz Untamala on 26/02/16.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import SelectCustom from './SelectCustom';

import * as _ from 'lodash';
import * as classNames from 'classnames';
import {List} from 'immutable';
import {getFieldError} from '../../util/index';

import './SelectInput.less';

interface Props {
  model: any;
  modelName?: string;
  field: string;
  options: any[];
  labelKey?: string;
  disabled?: boolean;
  multi?: boolean;
  placeholder?: string;
  forceShowError?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  formGroupClassName?: object;
  disableValidate?: boolean;
  hasError?: boolean;
  hasMore?: boolean;
  loadMore?: () => any;
  onChange: (model) => any;
  t: (key, params?) => any;
}

interface State {
  showError: boolean;
}

export default class SelectInput extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getNewState();
  }

  getNewState() {
    return {showError: this.props.forceShowError};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.model._id !== this.props.model._id && this.state.showError) {
      this.setState({showError: false});
    }
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: this.props.forceShowError ? true : false});

  shouldShowError = () =>
    this.state.showError || this.props.hasError;

  onSelectChange = selectedOption => {
    const fieldName = this.props.field;
    let value;

    if (!selectedOption) {
      value = null;
    } else if (List.isList(this.props.model.get(fieldName))) {
      value = List(_.map(_.isArray(selectedOption) ? selectedOption : [selectedOption], 'value'));
    } else {
      value = selectedOption.value;
    }

    this.props.onChange(this.props.model.set(fieldName, value));
  };

  getError = fieldError => {
    if (fieldError && this.shouldShowError()) {
      return <div className='error'>{fieldError}</div>;
    }
  };

  getLabelClassName = (fieldError) => {
    return classNames(
      this.props.labelClassName ? this.props.labelClassName : 'col-12 col-sm-3',
      'form-label',
      'select-input__label',
      {'select-input__label--error': fieldError && this.shouldShowError()}
    );
  };

  getFieldClassName = field => {
    return classNames([
      this.props.fieldClassName ? this.props.fieldClassName : 'col-12 col-sm-9',
      field
    ]);
  };

  getFormGroupClassName = fieldError => {
    return classNames(
      _.merge(
        {
          'form-group': true,
          'row': true,
          'has-error': fieldError && this.shouldShowError()
        },
        this.props.formGroupClassName || {}
      )
    );
  };

  getValue = (model, field, options, multi) => {
    let value = model.get(field);
    value = value ? (List.isList(value) ? value.toJS() : value) : [];

    const getSelectedOptions = () => {
      if (_.isArray(value)) {
        return options.filter(option => _.includes(value, option.value));
      } else {
        return options.filter(option => option.value === value);
      }
    };

    return multi ? getSelectedOptions() : _.first(getSelectedOptions());
  };

  getLabel = () => {

    const {
      field,
      labelKey,
      model,
      t,
      disableValidate,
      modelName
    } = this.props;

    if (!labelKey && !modelName) {
      return null;
    }

    const fieldError = disableValidate ? undefined : getFieldError(field, model.validate(), model.error);

    return (
      <label className={this.getLabelClassName(fieldError)} htmlFor='criteria'>
        {t(labelKey ? labelKey : modelName + 'View.' + field)}
      </label>
    );
  };

  render() {
    const {
      field,
      model,
      placeholder,
      disableValidate,
      disabled,
      multi,
      options,
      hasMore,
      loadMore,
      t
    } = this.props;
    const fieldError = disableValidate ? undefined : getFieldError(field, model.validate(), model.error);
    const value = this.getValue(model, field, options, multi);

    return (
      <div key={'select-' + field} className={this.getFormGroupClassName(fieldError)}>
        {this.getLabel()}
        <div className={this.getFieldClassName(field)}>
          <SelectCustom
            key='immutable_select'
            isDisabled={disabled}
            name='form-field-name'
            value={_.isEmpty(value) ? null : value}
            isMulti={!!multi}
            isClearable={true}
            options={options}
            onChange={this.onSelectChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            hasError={fieldError && this.shouldShowError()}
            hasMore={hasMore}
            loadMore={loadMore}
            placeholder={placeholder || t('select')}
          />
          {this.getError(fieldError)}
        </div>
      </div>
    );
  }
}
