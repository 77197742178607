import * as React from 'react';
import * as classnames from 'classnames';
import {connect} from 'react-redux';
import {useState} from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import PersonnelHeader from '../Header/PersonnelHeader';
import PersonnelFooter from '../Footer/PersonnelFooter';
import NavItems from '../NavItems/NavItems';
import {navigate} from '../../modules/Location';

const closeButton = require('../../images/close.svg');
import './SideDrawer.less';

interface DispatchProps {
  navigate: (url: string, silent?: boolean) => any;
}

interface OwnProps {
  onToggleDrawer: () => any;
  header?: string;
  footerText?: string;
}

interface StateProps {
  isPersonnel: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

export const useDrawer = (defaultOpenState) => {

  const [isDrawerOpen, setDrawerOpen] = useState(defaultOpenState);

  const toggleDrawer = () => {
    setDrawerOpen(isDrawerOpen => !isDrawerOpen);
  };

  return [isDrawerOpen, toggleDrawer];
};

const SideDrawer: React.FunctionComponent<Props> = (props) => {

  const {isPersonnel, onToggleDrawer, header, navigate, footerText} = props;

  const getHeader = () =>  {
    return isPersonnel ? <PersonnelHeader/> : <Header header={header}/>;
  };

  const getFooter = () =>  {

    const onLogoutClick = () => {
      navigate('/admin/logout');
    };

    return isPersonnel ?
      <PersonnelFooter className='side-drawer__footer' onLogoutClick={onLogoutClick}/> :
      <Footer className='side-drawer__footer' footerText={footerText}/>;
  };

  const getSideDrawerClassName = () => {
    return classnames(
      'side-drawer',
      {'side-drawer--personnel': isPersonnel},
      {'side-drawer--subject': !isPersonnel}
    );
  };

  return (
    <div className={getSideDrawerClassName()}>
      <div className='side-drawer__header'>
        {getHeader()}
        <img className='side-drawer__close' src={closeButton} onClick={onToggleDrawer} alt='close button'/>
      </div>
      <div className='side-drawer__nav'>
        <NavItems onToggleDrawer={onToggleDrawer}/>
      </div>
      {getFooter()}
    </div>
  );
};

const mapStateToProps = () => {
  const currentPage = window.location.href;
  const adminSiteRegex = /\/admin/i;

  return {
    isPersonnel: adminSiteRegex.test(currentPage)
  };
};

const mapDispatchToProps = {
  navigate
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(SideDrawer);
