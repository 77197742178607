import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import {Component} from 'react';
import {Form} from 'react-bootstrap';
import SelectInput from '../SelectInput/SelectInput';
import {generateSelectOptions} from '../../util';
import {ComponentProps, ComponentState, getError} from '../Question';

interface DropDownProps extends ComponentProps {
}

interface DropDownState extends ComponentState {
}

export default class DropDown extends Component<DropDownProps, DropDownState> {

  onChange = (updatedValue) => {

    const {onChange, answer} = this.props;

    onChange(answer.set('value', updatedValue));
  };

  renderDropdown = () => {

    const {component, answer, disabled, language, fieldError, t} = this.props;
    const {field, options} = component;

    const {value} = answer;

    if (_.isEmpty(options)) {
      return null;
    }

    const localization = component.getLanguageLocalization(language);
    const optionLabel = (option) => localization && localization[option];

    return (
      <SelectInput
        model={value}
        field={field}
        hasError={!!fieldError}
        options={generateSelectOptions(options, optionLabel)}
        onChange={this.onChange}
        disableValidate={true}
        t={t}
        disabled={disabled}
      />
    );
  };

  render() {
    const {fieldError} = this.props;
    const formGroupClass = classNames(
      'dropdown-input',
      {'has-error': !!fieldError}
    );

    return (
      <Form.Group className={formGroupClass}>
        {this.renderDropdown()}
        {getError(fieldError)}
      </Form.Group>
    );
  }
}