/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import {Form} from 'react-bootstrap';
import * as NumericInput from 'react-numeric-input';

import * as _ from 'lodash';
import * as classNames from 'classnames';
import './Input.less';

interface Props {
  value: string;
  onChange: (value) => any;
  id?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  wrapperClassName?: string;
  label?: (() => string | React.ReactNode) | (string | React.ReactNode);
  labelClassName?: string;
  i18nLoadedAt?: any;
  error?: any;
  hasError?: boolean;
  forceShowError?: boolean;
  groupClassName?: string;
  className?: string;
  afterInput?: React.ReactNode;
  t?: (key, params?) => any;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  onBlur?: () => any;
  tabIndex?: number;
  rowLayout?: boolean;
  maxLength?: number;
  rows?: number;
}

interface State {
  showError: boolean;
  forceShowError: boolean;
}

export default class InputComponent extends PureComponent<Props, State> {

  static defaultProps = {
    rowLayout: true
  };
  textInput: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      showError: this.props.forceShowError,
      forceShowError: this.props.forceShowError
    };
  }

  componentDidUpdate(prevProps) {
    const {forceShowError} = this.props;

    if (prevProps.forceShowError !== forceShowError) {
      this.setState({forceShowError, showError: forceShowError});
    }
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: this.props.forceShowError ? true : false});

  shouldShowError = () =>
    (this.props.error && this.state.showError) || (!this.props.disabled && this.props.hasError);

  getError = () => {
    if (this.shouldShowError()) {
      return <div className='error'>{this.props.t(this.props.error)}</div>;
    }
  };

  onInputChange = event => {
    let inputValue = event.target.value;
    if (inputValue === '') {
      inputValue = undefined;
    }

    return this.props.onChange(inputValue);
  };

  renderLabel = (label, labelClassName) => {

    const labelClasses = classNames({
      'form-label': true,
      'ctr-input__label': true,
      'ctr-input__label--error': this.shouldShowError()
    });

    if (label) {
      return (
        <Form.Label bsPrefix={`${labelClasses} ${labelClassName}`}>
          {_.isFunction(label) ? label() : label}
        </Form.Label>
      );
    }
  };

  renderInput = () => {

    const {
      onChange,
      value,
      disabled,
      type,
      rows,
      ...rest
    } = this.props;
    const restProps = _.omit(rest, ['t']);

    if (type === 'number') {

      return (<NumericInput
        className={classNames('form-control', restProps.className)}
        ref={input => {
          this.textInput = input;
        }}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={onChange}
        disabled={disabled}
        value={value}
        {..._.omit(restProps, 'className')}
      />);

    } else {

      return (
        <Form.Control
          rows={rows}
          inputRef={input => {
            this.textInput = input;
          }}
          as={type === 'textarea' ? 'textarea' : undefined}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onInputChange}
          disabled={disabled}
          value={value || ''}
          type={type === 'textarea' ? undefined : type}
          {...restProps}
        />
      );
    }
  };

  renderWordCount = () => {

    const {maxLength, value, type} = this.props;

    if (!maxLength || type !== 'textarea') {
      return;
    }

    const valueLength = value ? value.length : 0;

    const wordCountClassname = classNames(
      'ctr-input__count',
      {'ctr-input__count--error': valueLength > maxLength}
    );

    return (
      <p className={wordCountClassname}>{valueLength}/{maxLength}</p>
    );
  };

  render() {
    const formGroupClasses = classNames({
      'form-group': true,
      'row': this.props.rowLayout,
      'has-error': this.shouldShowError()
    });

    const {
      label,
      labelClassName,
      wrapperClassName,
      groupClassName,
      afterInput
    } = this.props;
    const groupClassNameProp = groupClassName
      ? formGroupClasses + ' ' + groupClassName
      : formGroupClasses;
    const help = this.getError();

    return (
      <Form.Group bsPrefix={groupClassNameProp}>
        {this.renderLabel(label, labelClassName)}
        <div className={`ctr-input ${wrapperClassName}`}>
          {this.renderInput()}
          {this.renderWordCount()}
          {help && <div>{help}</div>}
        </div>
        {afterInput}
      </Form.Group>
    );
  }
}
