import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import {Component} from 'react';
import Input from '../../../../components/Input/Input';
import {getFieldError} from '../../../../util';
import QuestionComponent from '../../../../models/QuestionComponent';

interface NumberFieldProps {
  language: string;
  component: QuestionComponent;
  onChange: (question: QuestionComponent) => any;
  t: (key, params?) => any;
}

interface NumberFieldState {
}

export default class NumberField extends Component<NumberFieldProps, NumberFieldState> {

  renderComponentLocalization = (
    field,
    localization,
    component,
    validationErrors,
    classes = ['col-12', 'col-sm-6']
  ) => {
    const {onChange, language, t} = this.props;

    const fieldError = getFieldError(
      `localization.${language}.${field}`,
      validationErrors,
      component.error
    );

    const onLocalizationChange = value => {
      const newLocalization = { ...localization};
      if (!newLocalization[language]) {
        newLocalization[language] = {};
      }
      newLocalization[language][field] = value;
      onChange(component.setLocalization(newLocalization));
    };

    const groupClassName = classes ? classNames(classes) : undefined;
    const value = localization && localization[language] ? localization[language][field] : '';

    return (
      <Input
        rowLayout={false}
        className={`question-component-localization-${field}`}
        groupClassName={groupClassName}
        key={`input-question-component-localization-${field}`}
        error={fieldError}
        onChange={onLocalizationChange}
        value={value}
        label={t(`questionnaire.form.componentLocalization.${field}`)}
        t={t}
      />
    );
  };

  renderLocalizationField = (field, classes) => {
    const {component, language} = this.props;
    const localization = component.getLocalization();
    const validationErrors = component.validateByLanguage(language);
    return this.renderComponentLocalization(
      field,
      localization,
      component,
      validationErrors,
      classes
    );
  };

  renderComponentOption = (
    field,
    type,
    options,
    component,
    validationErrors,
    classes = ['col-12', 'col-sm-6']
  ) => {
    const {onChange, t} = this.props;
    const fieldError = getFieldError(`options.${field}`, validationErrors, component.error);

    const onOptionChange = value => {
      const newOptions = { ...options, [field]: value};
      onChange(component.setOptions(newOptions));
    };

    const groupClassName = classes ? classNames(classes) : undefined;
    const value = options[field];

    return (
      <Input
        rowLayout={false}
        className={`question-component-option-${field}`}
        groupClassName={groupClassName}
        key={`input-question-component-option-${field}`}
        error={fieldError}
        onChange={onOptionChange}
        value={value}
        type={type}
        label={t(`questionnaire.form.componentOption.${field}`)}
        t={t}
      />
    );
  };

  renderOptionField = (field, type, classes) => {
    const {language, component} = this.props;
    const options = component.getOptions();
    const validationErrors = component.validateByLanguage(language);
    return this.renderComponentOption(field, type, options, component, validationErrors, classes);
  };

  render() {
    const {component} = this.props;
    const numberType = component.getType() === 'number';
    const sliderType = component.getType() === 'slider';

    return (
      <div className='component-number'>
        {this.renderLocalizationField('label', 'component-number__label')}
        {this.renderLocalizationField('unit', 'component-number__unit')}
        {this.renderOptionField('min', 'number', 'component-number__min')}
        {this.renderOptionField('max', 'number', 'component-number__min')}
        {numberType && this.renderOptionField('decimalDigits', 'number', 'component-number__decimal')}
        {sliderType && this.renderOptionField('steps', 'number', 'component-number__decimal')}
      </div>
    );
  };
}