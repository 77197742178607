import * as moment from 'moment';
import {parse} from 'query-string';
import * as momentLocalizer from 'react-widgets-moment';
import * as simpleNumberLocalizer from 'react-widgets-simple-number';
import {createAction, handleActions} from 'redux-actions';
import App from '../models/App';
import AuthenticatedUser from './AuthenticatedUser';
import {navigate, pushState} from './Location';
import {IDLE_TIME_LIMIT} from '../config/constants';

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = new App();
let checkIdleTimeInterval;

// ------------------------------------
// Constants
// ------------------------------------
export const INITIALIZE_START = 'App.INITIALIZE_START';
export const INITIALIZE_COMPLETE = 'App.INITIALIZE_COMPLETE';
export const INITIALIZE_FAILED = 'App.INITIALIZE_FAILED';
export const RESET = 'App.RESET';
const UNAUTHORIZED = 401;
// ------------------------------------
// Actions
// ------------------------------------
export const startInitialize = createAction(INITIALIZE_START, (payload) => payload);
export const completeInitialize = createAction(INITIALIZE_COMPLETE, (payload) => payload);
export const failedInitialize = createAction(INITIALIZE_FAILED, (payload) => payload);
export const resetAction = createAction(RESET);

export const initialize = () => {
  return (dispatch, getState) => {
    dispatch(startInitialize());

    window.addEventListener('storage', this.checkAccessToken(getState));

    momentLocalizer(moment);
    simpleNumberLocalizer();

    window.onpopstate = (_event) => {
      dispatch(pushState(document.location.pathname));
    };

    const {pathname, search} = document.location;
    const query = parse(location.search);
    const redirect = query.redirect ? query.redirect : pathname + (search ? search : '');

    if (!/^\/logout.*/.test(pathname)) {
      return dispatch(AuthenticatedUser.getAuthenticatedUser(redirect)).then(() =>
        dispatch(completeInitialize())
      );
    } else {
      return dispatch(completeInitialize());
    }
  };
};

export const teardown = () => {
  return (_dispatch, getState) => {

    window.removeEventListener('storage', this.checkAccessToken(getState));

    if (checkIdleTimeInterval) {
      clearInterval(checkIdleTimeInterval);
    }
  };
};

export function checkAccessToken(getState) {

  return (event: StorageEvent) => {

    const {authenticatedUser} = getState();
    const {authenticated} = authenticatedUser;

    if (event.key === 'token') {

      const notLoggedInWithAccessToken = !event.oldValue && !!event.newValue && !authenticated;
      const loggedInWithoutAccessToken = !!event.oldValue && !event.newValue && authenticated;

      if (notLoggedInWithAccessToken || loggedInWithoutAccessToken) {
        window.location.reload();
      }
    }
  };
}

export const actions = {
  initialize
};

export function checkIdleTime() {
  return (dispatch, getState) => {
    const {app} = getState();

    if (app.lastActionTime) {
      const duration = moment().diff(app.lastActionTime, 'minutes');

      if (duration >= IDLE_TIME_LIMIT) {
        dispatch(AuthenticatedUser.logout());
      }
    }
  };
}

export function handleGenericError(error) {
  return (dispatch) => {

    switch (error.code) {
      case UNAUTHORIZED:
        dispatch(AuthenticatedUser.resetAuthenticatedUser());
        dispatch(resetAction());
        dispatch(navigate('/'));
        break;
      default:
    }

    throw error;
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const startOfInitialize = (state) => {
  return state
    .set('initializing', true)
    .set('initialized', false)
    .set('error', null);
};

const endOfInitialize = (state, error?) => {
  return state
    .set('initializing', false)
    .set('initialized', !error)
    .set('error', error);
};

const ACTION_HANDLERS = {
  [INITIALIZE_START]: (state) => startOfInitialize(state),
  [INITIALIZE_COMPLETE]: (state) => endOfInitialize(state),
  [INITIALIZE_FAILED]: (state, {payload}) => endOfInitialize(state, payload),
  [RESET]: () => endOfInitialize(initialState)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
