import * as React from 'react';
import {PureComponent} from 'react';
import {Form} from 'react-bootstrap';

import * as classNames from 'classnames';
import CheckInput from '../CheckInput/CheckInput';

interface Props {
  field: string;
  label: string | React.ReactNode;
  value: boolean;
  onChange: (event) => any;
  groupClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
}

interface State {}

export default class CheckboxFormInput extends PureComponent<Props, State> {
  render() {
    const {
      groupClassName,
      labelClassName,
      inputClassName,
      label,
      field,
      value,
      disabled,
      onChange
    } = this.props;
    return (
      <Form.Group bsPrefix={classNames('form-group', groupClassName)}>
        <Form.Label bsPrefix={classNames('form-label', labelClassName)}>{label}</Form.Label>
        <div className={inputClassName}>
          <CheckInput
            type='checkbox'
            name={field}
            id={`checkbox-${field}`}
            checked={value}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      </Form.Group>
    );
  }
}
