import * as React from 'react';
import {PureComponent} from 'react';
import Input from '../Input/Input';
import * as classNames from 'classnames';
import {getFieldError, generateSelectOptions} from '../../util/index';
import StudySite from '../../models/StudySite';
import Studies from '../../models/Studies';
import SelectInput from '../SelectInput/SelectInput';

interface Props {
  studySite: StudySite;
  studies: Studies;
  showError?: boolean;
  hasMore?: boolean;
  loadMore?: () => any;
  onChange: (model) => any;
  t: (key, params?) => any;
}

export default class StudySiteDetails extends PureComponent<Props, any> {

  private static readonly inputs = [
    'studyId',
    'siteNumber',
    'siteName',
    'address',
    'info',
    'email'
  ];

  onFieldChangeCallback = field => (value = null) => {

    const {studySite, onChange} = this.props;

    onChange(studySite.set(field, value));
  };

  getStudyLabel = study => study.visibleName();

  getStudyOptions = () => generateSelectOptions(this.props.studies.list, this.getStudyLabel, 'id');

  createSelectInput = (field, options) => {

    const {studySite, onChange, t, showError, hasMore, loadMore} = this.props;
    const value = studySite.get(field);
    const fieldError = getFieldError(field, studySite.validate(), studySite.error, value);

    return (
      <SelectInput
        key={'select-' + field}
        model={studySite}
        modelName='studySite'
        field={field}
        options={options}
        onChange={onChange}
        labelClassName='col-12 col-sm-2'
        fieldClassName='col-12 col-sm-10'
        hasError={showError && !!fieldError}
        hasMore={hasMore}
        loadMore={loadMore}
        t={t}
      />
    );
  };

  getInputField = field => {

    const {studySite, t, showError} = this.props;
    const value = studySite.get(field);
    const fieldError = getFieldError(field, studySite.validate(), studySite.error, value);
    const wrapperClassName = classNames(['col-12 col-sm-10', field]);
    const type = (field === 'address' || field === 'info')
      ? 'textarea'
      : 'text';

    return (
      <Input
        key={'input_' + field}
        label={t('studySiteView.' + field)}
        labelClassName='col-12 col-sm-2'
        error={fieldError}
        hasError={showError && !!fieldError}
        onChange={this.onFieldChangeCallback(field)}
        value={value || ''}
        wrapperClassName={wrapperClassName}
        type={type}
        t={t}
      />
    );
  };

  renderInputs = () => {

    return StudySiteDetails.inputs.map(field => {

      switch (field) {
        case 'studyId':
          return this.createSelectInput(field, this.getStudyOptions());
        default:
          return this.getInputField(field);
      }
    });
  };

  render() {

    return (
      <div>
        <form >
          {this.renderInputs()}
        </form>
      </div>
    );
  }
}
