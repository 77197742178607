import * as React from 'react';
import {PureComponent} from 'react';
import * as Loader from 'react-loader';
import ResetPasswordForm from '../components/ResetPasswordForm';

import {connect} from 'react-redux';
import User from '../../../models/User';
import AuthenticatedUser from '../../../modules/AuthenticatedUser';
import {navigate} from '../../../modules/Location';
import {WithNamespaces, withNamespaces} from 'react-i18next';

import '../../../styles/components/authentication.less';
import {getFieldError} from '../../../util';
import {UserIsNotAuthenticated} from '../../../config/policies';
import {FORGOT_PASSWORD_URL} from '../../../config/constants';

interface Props extends WithNamespaces {
  user: User;
  token: string;
  resetPassword: (request) => any;
  validateToken: (token) => any;
  navigate: (url) => any;
}

interface State {
}

@UserIsNotAuthenticated
class ResetPasswordView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const {token, validateToken} = this.props;

    validateToken(token);
  }

  render() {
    const {user, t, resetPassword, navigate} = this.props;

    if (user.isValidatingToken) {
      return (
        <div style={{height: '100%'}}>
          <Loader loaded={false} width={12} radius={35} />
        </div>
      );
    }

    const tokenError = getFieldError('token', null, user.error);

    if (tokenError) {

      const onLinkClick = () => {
        navigate(FORGOT_PASSWORD_URL);
      };

      return (
        <div className='container invalid-token'>
          <img src={''} alt='' />
          <p className='error'>
            {t(tokenError)}
            <span className='login-link' onClick={onLinkClick}>
              {t('here')}
            </span>
          </p>
        </div>
      );
    }

    return (
      <div className='container reset'>
        <ResetPasswordForm
          model={user}
          onSubmit={resetPassword}
          t={t}
        />
      </div>
    );
  }
}

const mapActionCreators = {
  login: AuthenticatedUser.login,
  validateToken: AuthenticatedUser.validateToken,
  resetPassword: AuthenticatedUser.resetPassword,
  navigate
};

const mapStateToProps = ({authenticatedUser}, ownProps) => ({
  user: authenticatedUser,
  token: ownProps.location.query.token
});

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps,
    mapActionCreators
  )(ResetPasswordView)
);
