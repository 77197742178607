import {enumValues} from '../util';

export enum Language {
  fi = 'fi',
  en = 'en'
}

export namespace Language {

  const values = enumValues(Language);

  export function getValues() {

    return values;
  }
}
