import * as React from 'react';
import * as _ from 'lodash';
import {PureComponent} from 'react';
import {Form} from 'react-bootstrap';
import Select from 'react-select';
import QuestionComponent, {Type} from '../../../models/QuestionComponent';
import {enumValues, getQuestionFieldName} from '../../../util';
import {SelectOption} from '../../../config/constants';
import RoundButton from '../../../components/RoundButton/RoundButton';
import NumberField from './QuestionComponent/NumberField';
import InstructionField from './QuestionComponent/InstructionField';
import SelectOptionFields from './QuestionComponent/SelectOptionFields';

const deleteIcon = require('../../../images/delete.svg');

interface ComponentProps {
  component: QuestionComponent;
  language: string;
  onChange: (question: QuestionComponent) => void;
  onDelete: (question: QuestionComponent) => void;
  hasError?: boolean;
  t: (key, params?) => any;
}

interface ComponentState {}

export default class QuestionComponentView extends PureComponent<ComponentProps, ComponentState> {
  onMandatoryChange = () => {
    const {onChange, component} = this.props;
    onChange(component.setMandatory(!component.isMandatory()));
  };

  renderComponentMandatory = () => {
    const {component, t} = this.props;
    const field = 'mandatory';
    const value = component.isMandatory();

    if (component.isInstruction()) {

      return null;
    }

    return (
      <Form.Check
        type='checkbox'
        key={`checkbox-component-mandatory-${field}`}
        checked={value}
        label={t(`questionnaire.form.${field}`)}
        onChange={this.onMandatoryChange}/>
    );
  };

  getOption = (value: string): SelectOption<string> => {
    const {t} = this.props;

    return {
      label: value ? t(`questionnaire.form.questionType.${value}`) : t('questionnaire.form.type'),
      value
    };
  };

  getComponentOptions = () => enumValues(Type).map(this.getOption);

  onSelectComponentType = (option: SelectOption<Type>) => {
    const {component, onChange, language} = this.props;
    const {value} = option;

    const newComponent = component
      .setField(getQuestionFieldName(value))
      .setTypeAndDefaultMandatory(value)
      .setDefaultLocalization(language)
      .setDefaultOptions();

    onChange(newComponent);
  };

  renderSelectComponentType = () => {
    const {component, t} = this.props;
    const options = this.getComponentOptions();
    const option = options.find(o => o.value === component.getType());

    return (
      <div className='form-group question-content__select'>
        <label className='form-label'>{t('questionnaire.form.type')}</label>
        <Select
          key='select-component-type'
          name='form-field-name'
          value={option}
          options={this.getComponentOptions()}
          onChange={this.onSelectComponentType}
        />
      </div>
    );
  };

  renderQuestionComponent = () => {
    const {component, language, onChange, t, hasError} = this.props;

    switch (component.getType()) {
      case Type.instruction:
        return (<InstructionField
          component={component}
          language={language}
          onChange={onChange}
          t={t}
        />);
      case Type.select:
      case Type.multiselect:
      case Type.dropdown:
        return (<SelectOptionFields
          component={component}
          language={language}
          onChange={onChange}
          hasError={hasError}
          t={t}
        />);
      case Type.number:
      case Type.slider:
        return (<NumberField
          component={component}
          language={language}
          onChange={onChange}
          t={t}
        />);
      default:
        return null;
    }
  };

  onDeleteComponent = () => {
    const {onDelete, component} = this.props;

    onDelete(component);
  };

  renderDeleteComponent = () => {
    return (
      <RoundButton iconSrc={deleteIcon}
        shape='circle'
        className='question-content__delete'
        size='small'
        onClick={this.onDeleteComponent}/>
    );
  };

  render() {
    return (
      <div className='row question-content'>
        <div className='question-content__component'>
          {this.renderQuestionComponent()}
          {this.renderComponentMandatory()}
        </div>
        <div className='question-content__action'>
          {this.renderSelectComponentType()}
          {this.renderDeleteComponent()}
        </div>
      </div>
    );
  }
}
