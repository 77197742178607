import * as _ from 'lodash';
import BaseModel from './BaseModel';
import OrderedModel from './OrderedModel';
import {Type} from './QuestionComponent';

export enum RuleType {
  RANGE = 'range',
  MATCH_ALL = 'matchAll',
  MATCH_ONE_OF = 'matchOneOf'
}

const constraints = {
  page: {
    presence: {allowEmpty: false}
  },
  order: {
    presence: {allowEmpty: false}
  },
  field: {
    presence: {allowEmpty: false}
  },
  type: {
    presence: {allowEmpty: false}
  },
  options: {
    presence: {allowEmpty: false},
    length: {
      minimum: 1
    }
  }
};

const equalsKeys = ['order', 'page', 'field'];

const defaultValues = {
  page: undefined,
  order: undefined,
  field: undefined,
  type: undefined,
  options: undefined
};

export default class QuestionnaireRule
  extends BaseModel(defaultValues, equalsKeys, constraints)<QuestionnaireRule>
  implements OrderedModel<QuestionnaireRule> {
  page: number;
  order: number;
  field: string;
  type: RuleType;
  options: object | string[];

  static getSupportedComponentTypes(): Type[] {

    return [
      Type.select,
      Type.multiselect,
      Type.slider,
      Type.number,
      Type.dropdown
    ];
  }

  static resolveType(field: string): string {
    return field && field.startsWith(Type.select)
      ? RuleType.MATCH_ONE_OF : field.startsWith(Type.slider) || field.startsWith(Type.number) ? RuleType.RANGE
        : RuleType.MATCH_ALL;
  }

  constructor(js?: any) {
    super(js);
  }

  fromJS(js: any): QuestionnaireRule {
    return new QuestionnaireRule(js);
  }

  getPage() {
    return this.get('page');
  }

  setPage(page: number) {
    return this.set('page', page) as QuestionnaireRule;
  }

  getOrder() {
    return this.get('order');
  }

  setOrder(order: number) {
    return this.set('order', order) as QuestionnaireRule;
  }

  getField() {
    return this.get('field');
  }

  setField(field: string) {
    return this.set('field', field) as QuestionnaireRule;
  }

  getType() {
    return this.get('type');
  }

  setType(type: string) {
    return this.set('type', type) as QuestionnaireRule;
  }

  getOptions() {
    return this.get('options');
  }

  setOptions(options: object) {
    return this.set('options', options) as QuestionnaireRule;
  }
}