/**
 * Created by Mauritz Untamala on 01/03/2017.
 */
import {List, Record} from 'immutable';
import * as _ from 'lodash';
import {Language} from './Language';
import {UserRole} from './UserRole';

export class NavItems extends Record({
  default: List(),
  study: List(),
  selectableStudies: List()
}) {
  default: List<any>;
  study: List<any>;
  selectableStudies: List<any>;
}

const defaultValues = {
  initializing: false,
  initialized: false,
  navItems: new NavItems(),
  availableRoles: List(UserRole.getValues()),
  availableLanguages: List(Language.getValues()),
  error: undefined,
  lastActionTime: undefined
};

class App extends Record(defaultValues) {
  initializing: boolean;
  initialized: boolean;
  navItems: NavItems;
  availableRoles: List<any>;
  availableLanguages: List<any>;
  error: any;
  lastActionTime: any;

  public setNavItems(navItemsJS) {
    const navItems = this.navItems
      .set('default', List(navItemsJS.default))
      .set('study', List(navItemsJS.study));

    return this.set('navItems', navItems);
  }

  public setAvailableRoles(availableRolesJS) {
    return this.setListArray('availableRoles', availableRolesJS);
  }

  public setAvailableLanguages(availableLanguagesJS) {
    return this.setListArray('availableLanguages', availableLanguagesJS);
  }

  public setUIActions(uiActionsJS) {
    return this.setListArray('uiActions', uiActionsJS);
  }

  public getNavItems() {
    return this.navItems;
  }

  public getSelectableStudies() {
    return this.navItems.selectableStudies;
  }

  public setLastActionTime(lastActionTime) {
    return this.set('lastActionTime', lastActionTime);
  }

  private setListArray(key, arrayJS) {
    return this.set(key, List(_.isArray(arrayJS) ? arrayJS : []));
  }
}

export default App;
