/**
 * Created by Mauritz Untamala on 05/12/15.
 */

import * as React from 'react';
import {Component, PureComponent} from 'react';
import Input from './Input/Input';
import {processedData} from './TableComponentUtil';

import * as _ from 'lodash';
import * as classNames from 'classnames';

interface TopSectionProps {
  criteria: any;
  onFilterCriteriaChanged: (filter) => any;
  content: object;
  t: (key, params?) => any;
}

interface TopSectionState {
  filter: any;
}

class TopSection extends Component<TopSectionProps, TopSectionState> {
  readonly debouncedCriteriaChanged;

  constructor(props: TopSectionProps) {
    super(props);
    this.state = {filter: props.criteria.filter};
    this.debouncedCriteriaChanged = _.debounce(this.props.onFilterCriteriaChanged, 500);
  }

  onFilterCriteriaChanged = value => {
    this.setState({filter: value});
    this.debouncedCriteriaChanged(value);
  };

  render() {
    return (
      <div className='top-section'>
        <div className='simple-table-filter'>
          <Input
            rowLayout={false}
            type='text'
            name='filter'
            placeholder='Filter results'
            value={this.state.filter || ''}
            onChange={this.onFilterCriteriaChanged}
            t={this.props.t}
          />
        </div>
        {this.props.content}
      </div>
    );
  }
}

interface HeadProps {
  columns: any[];
  criteria: any;
  onSortCriteriaChanged: (criteria) => any;
}

interface HeadState {
}

class Head extends Component<HeadProps, HeadState> {
  getSortState = field => {
    if (this.props.criteria.columnName === field) {
      return this.props.criteria.sort === 'asc' ? 'desc' : 'asc';
    }

    return 'asc';
  };

  onClick = columnName => {
    this.props.onSortCriteriaChanged({
      columnName,
      sort: this.getSortState(columnName)
    });
  };

  column = columnSettings => {
    const columnName = columnSettings.columnName;
    const criteria = this.props.criteria;
    const sortState = criteria.columnName === columnName ? criteria.sort : undefined;

    let classes = classNames({
      'sort-ascending': sortState === 'asc',
      'sort-descending': sortState === 'desc',
      'sort-none': columnSettings.sortable !== false && !sortState
    });

    if (columnSettings.cssClassName) {
      classes = classNames(classes, columnSettings.cssClassName);
    }

    let onClick = null;

    if (columnSettings.sortable !== false) {
      onClick = this.onClick.bind(this, columnName);
    }

    return (
      <th key={columnName} className={classes} onClick={onClick}>
        <span>{columnSettings.displayName}</span>
        <span className='sort-icon'/>
      </th>
    );
  };

  columns = () => _.map(this.props.columns, this.column);

  render() {
    return (
      <thead>
        <tr>{this.columns()}</tr>
      </thead>
    );
  }
}

interface TableRowProps {
  rowData: any;
  columns: any[];
  onRowClick: (rowData) => any;
  t: (key, params?) => any;
}

interface TableRowState {
}

class TableRow extends Component<TableRowProps, TableRowState> {
  column = columnSettings => {
    const data = _.get(this.props.rowData, columnSettings.columnName);
    const content = columnSettings.customComponent ? (
      <columnSettings.customComponent
        data={data}
        t={this.props.t}
        rowData={this.props.rowData}
        column={columnSettings.columnName}
      />
    ) : (
      data
    );

    let classes;

    if (columnSettings.cssClassName) {
      classes = classNames(classes, columnSettings.cssClassName);
    }

    return (
      <td key={columnSettings.columnName} className={classes}>
        {content}
      </td>
    );
  };

  columns = () => _.map(this.props.columns, this.column);

  onRowClick = () => {
    if (this.props.onRowClick) {
      this.props.onRowClick(this.props.rowData);
    }
  };

  render() {
    const {rowData} = this.props;

    return (
      <tr key={rowData.id || rowData._id} onClick={this.onRowClick}>
        {this.columns()}
      </tr>
    );
  }
}

interface Props {
  data: any[];
  columns: any[];

  showFilter?: boolean;
  initialFilter?: string;
  initialSortColumn?: string;
  initialSortState?: string;
  rowKey?: string;
  useFixedHeader?: boolean;
  topContent?: any;
  onProcessedData?: any;
  onCriteriaChanged?: (criteria) => any;
  onRowClick?: (rowData) => any;

  loading?: boolean;
  hasMore?: boolean;
  loadMore?: () => any;
  ref?: any;

  t: (key, params?) => any;
}

interface State {
  filter: string;
  columnName: string;
  sort: string;
  loading: boolean;
}

export default class TableComponent extends PureComponent<Props, State> {
  scroll;

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: props.initialFilter,
      columnName: props.initialSortColumn,
      sort: props.initialSortState,
      loading: props.loading
    };
  }

  componentDidMount() {
    this.setupInfiniteScrolling();
  }

  componentDidUpdate() {
    this.setupInfiniteScrolling();
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  scrollListener = () => {
    const element = this.scroll;
    const scrollTop = (element && element.scrollTop) || document.body.scrollTop;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const scrolledToBottom = scrollTop + element.clientHeight >= scrollHeight - 20;
    const {hasMore, loadMore} = this.props;

    if (this.hasScrollBar() && scrolledToBottom && hasMore && loadMore) {
      this.detachScrollListener();
      this.props.loadMore();
    }
  };

  hasScrollBar = () => {
    const element = this.scroll;
    const scrollHeight = (element && element.scrollHeight) || document.body.scrollHeight;
    const clientHeight = (element && element.clientHeight) || document.body.clientHeight;

    return scrollHeight > clientHeight;
  };

  scrollToTop = () => {
    const element = this.scroll;
    element.scrollTop = 0;
  };

  attachScrollListener = () => {
    if (!this.props.hasMore || !this.isInfiniteScroll()) {
      return;
    }

    if (this.scroll) {
      this.scroll.addEventListener('scroll', this.scrollListener);
      this.scroll.addEventListener('resize', this.scrollListener);
      this.scrollListener();
    }
  };

  setupInfiniteScrolling = () => {
    if (!this.isInfiniteScroll()) {
      return;
    }

    this.attachScrollListener();

    const {hasMore, loading, loadMore} = this.props;

    // Load as much data until view get's scroll bar if there is data to be loaded.
    if (hasMore && !this.hasScrollBar() && !loading) {
      loadMore();
    }
  };

  detachScrollListener = () => {
    if (!this.isInfiniteScroll()) {
      return;
    }

    if (this.scroll) {
      this.scroll.removeEventListener('scroll', this.scrollListener);
      this.scroll.removeEventListener('resize', this.scrollListener);
    }
  };

  row = data => {
    return (
      <TableRow
        key={data[this.props.rowKey] || data._id}
        rowData={data}
        t={this.props.t}
        columns={this.props.columns}
        onRowClick={this.props.onRowClick}
      />
    );
  };

  rows = () => _.map(this.getData(), this.row);

  isInfiniteScroll = () => !!this.props.loadMore;

  getData = () => {
    if (this.isInfiniteScroll()) {
      return this.props.data;
    } else {
      return processedData(this.state, this.props.data, this.props.columns);
    }
  };

  onCriteriaChanged = criteria => {
    this.setState(criteria, () => {
      if (this.props.onCriteriaChanged) {
        this.scrollToTop();
        this.props.onCriteriaChanged(this.state);
      }
    });
  };

  onFilterCriteriaChanged = filter => this.onCriteriaChanged({filter});

  getHead = () => {
    return (
      <Head
        columns={this.props.columns}
        criteria={this.state}
        onSortCriteriaChanged={this.onCriteriaChanged}
      />
    );
  };

  getFixedHeader = apply => {
    if (!apply) return;

    return <table>{this.getHead()}</table>;
  };

  getNormalHeader = apply => {
    if (!apply) return;

    return this.getHead();
  };

  getTopSection = () => {

    const {showFilter, t, topContent} = this.props;

    if (showFilter) {
      return (
        <TopSection
          criteria={this.state}
          onFilterCriteriaChanged={this.onFilterCriteriaChanged}
          content={topContent}
          t={t}
        />
      );
    }
  };

  loadingRow = () => {

    const {loading, t, columns} = this.props;

    if (loading) {
      return (
        <tr>
          <td colSpan={columns.length} className='loading'>
            <span>{t('loading')}</span>
          </td>
        </tr>
      );
    }
  };

  render() {

    const {useFixedHeader} = this.props;

    return (
      <div className='simple-table'>
        {this.getTopSection()}
        <div className='simple-table-container'>
          <div className='simple-table-body'>
            <div className='simple-table-wrapper'>
              {this.getFixedHeader(useFixedHeader)}
              <div className='simple-table-row-wrapper' ref={scroll => (this.scroll = scroll)}>
                <table className='simple-table-rows'>
                  {this.getNormalHeader(!useFixedHeader)}
                  <tbody>
                    {this.rows()}
                    {this.loadingRow()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
