import * as React from 'react';
import * as classnames from 'classnames';
import {IndexLink} from 'react-router';
import './NavItem.less';

interface Props {
  path: string;
  name: string;
  active: boolean;
  iconSrc?: string;
  type?: 'static' | 'interactive';
  onClick: () => any;
}

const NavItem: React.FunctionComponent<Props> = (props) => {

  const {path, name, iconSrc, type, active, onClick} = props;

  return (
    <IndexLink
      onClick={onClick}
      className={classnames(
        `navItem navItem--${type}`,
        {active}
      )}
      to={path}>
      {iconSrc ? <img className='navItem__icon' src={iconSrc} alt='Navigation Icon'/> : null}
      <div className='navItem__text'>
        {name}
      </div>
    </IndexLink>
  );
};

NavItem.defaultProps = {
  type: 'static'
};

export default NavItem;
