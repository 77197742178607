import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import {Component} from 'react';
import {Form, FormGroup} from 'react-bootstrap';
import {ComponentProps, ComponentState, getError} from '../Question';
import {fromJS} from 'immutable';
import {Type} from '../../models/QuestionComponent';
import SliderInput from '../SliderInput/SliderInput';
import NumberPicker from '../../routes/Subject/Questionnaires/NumberPicker';

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''));
const compactRecord = record => fromJS(compactObject(record.toJS()));

interface NumberProps extends ComponentProps {
}

interface NumberState extends ComponentState {
}

export default class Number extends Component<NumberProps, NumberState> {
  onChange = value => {
    const {component, answer} = this.props;
    const {field} = component;

    const updatedValue = answer.value.set(field, value);
    this.props.onChange(answer.set('value', compactRecord(updatedValue)));
  };

  render() {
    const {component, answer, fieldError, disabled, language} = this.props;
    const languageLocalization = component.getLanguageLocalization(language)
      || {label: '', unit: ''};
    const {label, unit} = languageLocalization;

    const {field, options, type} = component.toJS();
    const value = answer.getFieldValue(field);
    const {min, max, format, decimalDigits, steps} = options || {} as Partial<NonNullable<typeof value>>;

    const getUnit = () => {
      if (unit) {
        return (<span>({unit})</span>);
      }
    };

    const parse = (strVal) => {
      strVal = strVal.replace(',', '.');
      return parseFloat(strVal);
    };

    const formGroupClass = classNames(
      'question-input',
      {'has-error': !!fieldError}
    );

    return (
      <FormGroup className={formGroupClass}>
        <Form.Label className='question-input__label'>
          {label} {getUnit()}
        </Form.Label>
        {(type === Type.slider) ?
          <SliderInput
            onChange={this.onChange}
            value={value}
            min={min}
            max={max}
            disabled={disabled}
            step={steps || 1}
          />
          :
          <NumberPicker
            key={`input_${field}`}
            min={min}
            max={max}
            onChange={this.onChange}
            value={value}
            format={`${format ? format : '-##'}`}
            decimalDigits={decimalDigits || 0}
            parse={parse}
            readOnly={false}
          />
        }
        {getError(fieldError)}
      </FormGroup>
    );
  }
}