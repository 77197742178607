import {List} from 'immutable';
import * as _ from 'lodash';
import moment from 'moment-es6';
import BaseModel from './BaseModel';
import {stringToIntArray} from '../util';
import {setDateFields} from './Common';

const defaultValues = {
  from: undefined,
  to: undefined,
  users: List(),
  groups: List(),
  studies: List(),
  study: undefined,
  tab: undefined,
  type: undefined,
  columnName: undefined,
  sort: undefined,
  filter: undefined,
  locked: false
};

const idLists = ['users', 'groups', 'studies'];
const dateFields = ['from', 'to'];

export default class Criteria extends BaseModel(defaultValues)<Criteria> {
  from: any;
  to: any;
  users: List<number>;
  studies: List<number>;
  study: any;
  tab: any;
  type: undefined;
  columnName: any;
  sort: any;
  filter: any;
  locked: boolean;

  static getInitialCriteriaWithoutDateRange(js = {}) {
    return new Criteria(_.merge({filter: ''}, js));
  }

  static fromQuery(js: any = {}): Criteria {
    if (js) {
      idLists.forEach(field => {
        js[field] = stringToIntArray(js[field]);
      });
    }

    return new Criteria(js);
  }

  static getInitialCriteria() {
    const from = moment()
      .subtract(4, 'months')
      .startOf('day');
    const to = moment().endOf('day');

    return new Criteria({from, to});
  }

  constructor(js?) {
    super(js);

    if (js) {
      const criteria = this.setListArray(idLists, js) as Criteria;

      return setDateFields(criteria, dateFields);
    }
  }

  fromJS(js: any = {}): Criteria {
    return new Criteria(js);
  }

  listToParams(queryParams, fields) {
    _.each(
      fields,
      function(field) {
        const list = this[field];

        if (list) {
          const array = list.reduce(function(accu, id) {
            if (accu !== '') {
              accu = accu + ',';
            }
            return accu + id;
          }, '');
          if (array !== '') {
            queryParams[field] = array;
          }
        }
      }.bind(this)
    );
  }

  fieldsToParams(queryParams, fields) {
    _.each(
      fields,
      function(field) {
        if (this[field]) {
          queryParams[field] = this[field];
        }
      }.bind(this)
    );
  }

  getQueryParams() {
    const queryParams = {};

    this.listToParams(queryParams, ['users', 'studies']);
    this.fieldsToParams(queryParams, [
      'tab',
      'study',
      'type',
      'filter',
      'columnName',
      'sort',
      'locked'
    ]);

    _.each(
      ['from', 'to'],
      function(dateField) {
        if (this[dateField]) {
          queryParams[dateField] = this[dateField].toISOString();
        }
      }.bind(this)
    );

    return queryParams;
  }

  sanitizeCriteria(user, users) {

    let criteria = this as Criteria;

    // Add users selected site study as default if empty
    if (criteria.studies.isEmpty()) {
      criteria = criteria.set('siteStudies', List.of(user.selectedStudyId)) as Criteria;
    }

    if (!users.isEmpty()) {
      // Filter users not included in selected site studies
      const filteredUsers = criteria.users.filter(userId => {
        const user = users.getModelById(userId);
        if (!user) {
          return false;
        }
        return !!user.studyIds.find(id => criteria.studies.includes(id));
      });

      criteria = criteria.set('users', filteredUsers) as Criteria;
    }

    return criteria;
  }
}
