import * as React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {Form} from 'react-bootstrap';
import {PureComponent} from 'react';
import './EditorMCE.less';
import * as classNames from 'classnames';

interface Props {
  label?: string;
  labelClassName?: string;
  fieldClassName?: string;
  initialValue?: string;
  onEditorChange: (string) => void;
  error?: any;
  hasError?: boolean;
  t?: (key, params?) => any;
}

interface State {
  showError: boolean;
}

export default class EditorMCE extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  onBlur = () => this.setState({showError: true});

  onFocus = () => this.setState({showError: false});

  shouldShowError = () =>
    (this.props.error && this.state.showError) || (this.props.hasError);

  onChange = (content) => {
    this.props.onEditorChange(content);
  };

  getError = () => {
    if (this.shouldShowError()) {
      return <div className='error'>{this.props.t(this.props.error)}</div>;
    }
  };

  render() {
    const {label, initialValue, labelClassName, fieldClassName} = this.props;

    const toolbar = [
      'undo redo',
      'removeformat',
      'bold italic underline strikethrough',
      'fontsizeselect',
      'alignleft aligncenter alignright alignjustify',
      'outdent indent',
      'numlist bullist',
      'link'
    ].join('|');

    const plugins = 'link lists';
    const menubar = false;

    const labelClass = classNames(
      labelClassName,
      {'tinymce__label--error': this.shouldShowError()}
    );

    const formGroupClass = classNames(
      'form-group',
      'row',
      {'has-error': this.shouldShowError()}
    );

    return (
      <Form.Group className={formGroupClass}>
        <Form.Label className={labelClass}>{label}</Form.Label>
        <div className={fieldClassName}>
          <Editor
            initialValue={initialValue}
            init={{
              plugins,
              toolbar,
              menubar,
              relative_urls : false,
              remove_script_host : true
            }}
            onEditorChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
          {this.getError() && <div>{this.getError()}</div>}
        </div>
      </Form.Group>
    );
  }
};
