import * as React from 'react';
import * as classnames from 'classnames';
import './PagingIndicator.less';

interface Props {
  active: boolean;
  className?: string;
}

const PagingIndicator: React.FunctionComponent<Props> = (props) => {

  const {active, className} = props;

  const getStateClassName = () => {

    if (active) {
      return 'paging-indicator--active';
    }

    return null;
  };

  return (
    <div className={classnames('paging-indicator', getStateClassName(), className)}/>
  );
};

export default PagingIndicator;
