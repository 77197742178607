import * as React from 'react';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import * as classNames from 'classnames';

import SideDrawer, {useDrawer} from '../../components/SideDrawer/SideDrawer';
import MainNav from '../../components/MainNav/MainNav';
import './AdminNavigationLayout.less';

const logo = require('../../images/logo.svg');

interface Props extends WithNamespaces {
}

const AdminNavigationLayout: React.FunctionComponent<Props> = (props) => {

  const [isDrawerOpen, toggleDrawer] = useDrawer(false);

  const getSideDrawerStateClassName = () => {

    return isDrawerOpen ? 'admin-layout__side-drawer--open' : 'admin-layout__side-drawer--close';
  };

  const getAdminLayoutState = () => {

    if (isDrawerOpen) {
      return 'admin-layout--overflow-hidden';
    }
  };

  const closeDrawerIfOpen = () => {

    if (isDrawerOpen) {

      toggleDrawer();
    }
  };

  return (
    <div className={classNames('admin-layout', getAdminLayoutState())}>
      <header className='admin-layout__header'>
        <MainNav onToggleDrawer={toggleDrawer} logoUrl={logo}/>
      </header>
      <div className={classNames('admin-layout__side-drawer', getSideDrawerStateClassName())}>
        <SideDrawer onToggleDrawer={closeDrawerIfOpen} footerText={props.t('footerCopyRight')}/>
      </div>
      <div className='admin-layout__body'>
        <div className='admin-layout__content'>
          {props.children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({authenticatedUser}, _ownProps) => {
  return {
    user: authenticatedUser
  };
};

export default withNamespaces(['common'], {wait: true})(
  connect(
    mapStateToProps
  )(AdminNavigationLayout)
);
