/**
 * Created by Mauritz Untamala on 11/12/15.
 */
import * as React from 'react';
import * as _ from 'lodash';
import {PureComponent} from 'react';
import Results from './components/Results';
import {connect} from 'react-redux';
import {navigate} from '../../../modules/Location';
import StudiesModule from '../../../modules/Studies';
import StudyModule from '../../../modules/Study';
import App from '../../../models/App';
import Criteria from '../../../models/Criteria';
import StudiesModel from '../../../models/Studies';
import {PaginationContext} from '../../../models/Pagination';
import './Studies.less';
import {WithNamespaces, withNamespaces} from 'react-i18next';
import User from '../../../models/User';

interface Props extends WithNamespaces {
  user: User;
  app: App;
  studies: StudiesModel;
  criteria: Criteria;
  location: string;
  updateCriteria: (location, criteria) => any;
  getStudies: (queryParams?, reset?) => any;
  deleteStudy: (model) => any;
  navigate: (url: string, silent?: boolean) => any;
  pagination: any;
}

interface State {
}

export class Studies extends PureComponent<Props, State> {
  componentDidMount() {
    const {getStudies, criteria} = this.props;
    getStudies(criteria.getQueryParams());
  }

  onCriteriaChange = criteria => {
    const {updateCriteria, location} = this.props;
    updateCriteria(location, criteria);
  };

  loadMore = () => {
    const {getStudies, studies, criteria, pagination} = this.props;
    if (!studies.isLoading && pagination.hasMore) {
      getStudies(criteria.getQueryParams());
    }
  };

  render() {
    const {app, user, criteria, studies, deleteStudy, navigate, t, pagination} = this.props;

    return (
      <div key='studies-view-container' className='studies-view'>
        <h1 className='view-title__list'>
          {t('studiesView.title')}
        </h1>
        <Results
          user={user}
          ref='results'
          app={app}
          studies={studies.list}
          criteria={criteria}
          deleteModel={deleteStudy}
          onCriteriaChange={this.onCriteriaChange}
          navigate={navigate}
          t={t}
          hasMore={pagination.hasMore}
          loadMore={this.loadMore}
          loading={studies.isLoading}
        />
      </div>
    );
  }
}

const mapActionToProps = {
  updateCriteria: StudiesModule.updateCriteria,
  getStudies: StudiesModule.getModels,
  deleteStudy: StudyModule.deleteModel,
  navigate
};

const getCriteria = ({query}) => {
  const hasParams = !!_.find(Object.keys(query), key => key !== '');

  return hasParams ? Criteria.fromQuery(query) : Criteria.getInitialCriteriaWithoutDateRange();
};

const mapStateToProps = ({app, studies, authenticatedUser, pagination}, ownProps) => {
  const criteria = getCriteria(ownProps.location);

  return {
    user: authenticatedUser,
    app,
    criteria,
    studies,
    pagination: pagination.getPagination(PaginationContext.STUDY)
  };
};

export default withNamespaces(['common'], {wait: true})(connect(
  mapStateToProps,
  mapActionToProps
)(Studies));
