import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import {Component} from 'react';
import {Form} from 'react-bootstrap';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import {ComponentProps, ComponentState, getError} from '../Question';
import {fromJS} from 'immutable';

const compactObject = object => _.pickBy(object, _.negate(val => _.isNull(val) || val === ''));
const compactRecord = record => fromJS(compactObject(record.toJS()));

interface DatetimeProps extends ComponentProps {
}

interface DatetimeState extends ComponentState {
}

export default class Datetime extends Component<DatetimeProps, DatetimeState> {
  onChange = change => {
    const {component, answer} = this.props;
    const {field} = component;

    const updatedValue = answer.value.set(field, change);
    this.props.onChange(answer.set('value', compactRecord(updatedValue)));
  };

  render() {
    const {component, answer, fieldError, disabled} = this.props;
    const {field} = component;
    let value = answer.getFieldValue(field);
    if (value) {
      value = new Date(value);
    }

    const formGroupClass = classNames(
      {'has-error': !!fieldError}
    );

    return (
      <Form.Group className={formGroupClass}>
        <DateTimePicker
          value={value}
          disabled={disabled}
          onChange={this.onChange}/>
        {getError(fieldError)}
      </Form.Group>
    );
  }
}