/**
 * Created by Mauritz Untamala on 18/02/16.
 */
import * as React from 'react';
import {PureComponent} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import * as classNames from 'classnames';
import RoundButton from './RoundButton/RoundButton';

interface Props {
  model: any;
  save: () => any;
  onModelSaved: () => any;
  enabled?: boolean;
  t: (key, params?) => any;
}

interface State {
  timeoutId: any;
  animateEndOfTransition: boolean;
}

class SaveButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timeoutId: 0,
      animateEndOfTransition: false
    };
  }

  createUpdateButton(key) {
    const {t, save, enabled} = this.props;

    return (
      <RoundButton
        key={'label'}
        className={classNames('save-btn', key)}
        onClick={save}
        disabled={enabled === false}
      >
        {t('button.' + key)}
      </RoundButton>
    );
  }

  getTransitionButton(key) {
    let content;
    let loading;

    if (key === 'updated' || key === 'added') {
      content = <i className='fa fa-check'/>;
    } else {
      content = this.props.t('button.' + key);
      loading = <i className="loading-icon fa fa-spin fa-spinner"/>;
    }

    return (
      <RoundButton
        key={'label'}
        className={classNames('save-btn', key)}>
        {content}
        {loading}
      </RoundButton>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {model, onModelSaved} = this.props;
    const {animateEndOfTransition, timeoutId} = this.state;

    if (prevProps.model.isSaving && !model.isSaving && !animateEndOfTransition) {
      this.setState({animateEndOfTransition: true});
    }

    if (animateEndOfTransition && !timeoutId) {
      const timeoutId = setTimeout(() => {
        this.setState({
          animateEndOfTransition: false,
          timeoutId: null
        });
      }, 1500);

      this.setState({timeoutId});
    } else if (prevState.animateEndOfTransition && !animateEndOfTransition && onModelSaved) {
      onModelSaved();
    }
  }

  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  render() {
    const {model} = this.props;
    let content;

    if (model && model.isSaving) {
      content = this.getTransitionButton(model.error ? 'error' : model.id ? 'updating' : 'adding');
    } else if (this.state.animateEndOfTransition) {
      content = this.getTransitionButton(model.error ? 'error' : model.id ? 'updated' : 'added');
    } else if (model && model.id) {
      content = this.createUpdateButton('update');
    } else {
      content = this.createUpdateButton('add');
    }

    return (
      <TransitionGroup
        component='div'
        className='button-animation-wrapper'
        enter={true}
        exit={false}>
        <CSSTransition classNames='button-animation' timeout={2000}>
          {content}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default SaveButton;
