import {List} from 'immutable';
import BaseModel from './BaseModel';
import Picture, {PictureType} from './Picture';
import {Language} from './Language';

const constraints = {
  studyNumber: {
    presence: {allowEmpty: false}
  },
  studyName: {
    presence: {allowEmpty: false}
  },
  infoTitle: {
    presence: true
  },
  info: {
    presence: true
  },
  thankYou: {
    presence: true
  },
  thankYouIneligible: {
    presence: true
  },
  qualifiedInfo: {
    presence: true
  },
  languages: {
    presence: {allowEmpty: false},
    inclusion: Language.getValues()
  },
  studyImage: {
    custom: () => {
      const rule = (value) => {
        if(value !== null) {
          return !!value.filename && !value.error;
        } else {
          return true;
        }
      };
      return { rule, message: 'validate.noValidCoverImage'};
    }
  }
};

const interestedKeys = [
  'studyNumber',
  'studyName',
  'userIds',
  'languages',
  'infoTitle',
  'info',
  'thankYou',
  'thankYouIneligible',
  'qualifiedContent',
  'extraInfo'
];

const defaultValues = {
  studyNumber: undefined,
  studyName: undefined,
  infoTitle: undefined,
  info: undefined,
  thankYou: undefined,
  thankYouIneligible: undefined,
  qualifiedInfo: undefined,
  extraInfo: undefined,
  studyLogoId: undefined,
  studyLogo: new Picture({type: PictureType.LogoImage}),
  originalStudyLogo: undefined,
  studyImageId: undefined,
  studyImage: new Picture({type: PictureType.CoverImage}),
  originalStudyImage: undefined,
  userIds: List<number>(),
  languages: List<any>(),
  footer: undefined,
  socialSharing: undefined,
  askForAddress: undefined
};

export default class Study extends BaseModel(defaultValues, interestedKeys, constraints)<Study> {
  studyNumber: string;
  studyName: string;
  userIds: List<number>;
  languages: List<any>;
  infoTitle: string;
  info: string;
  thankYou: string;
  thankYouIneligible: string;
  qualifiedInfo: string;
  extraInfo: string;
  studyLogoId: number;
  studyLogo: Picture;
  originalStudyLogo: Picture;
  studyImageId: number;
  studyImage: Picture;
  originalStudyImage: Picture;
  footer: string;
  socialSharing: boolean;
  askForAddress: boolean;

  constructor(js?) {
    super(js);

    return this.setListArray(['userIds', 'languages'], js) as Study;
  }

  fromJS(js): Study {

    let model = new Study(js);

    ['studyLogo', 'studyImage'].forEach(field => {
      if (js[field]) {
        model = model.set(field, new Picture(js[field])) as Study;
      }
    });

    return model;
  }

  visibleName() {
    return this.studyName;
  }
}
