import * as React from 'react';
import {Form} from 'react-bootstrap';
import * as classnames from 'classnames';
import './CheckInput.less';

interface Props {
  id: string;
  onChange: any;
  onClick?: any;
  value: any;
  name?: string;
  type: 'checkbox' | 'radio';
  disabled?: boolean;
  hasError?: boolean;
  checked?: boolean;
  label?: string;
  bsPrefix?: string;
}

const Check: React.FunctionComponent<Props> = (props) => {

  const {
    disabled,
    id,
    label,
    bsPrefix,
    checked,
    value,
    onChange,
    onClick,
    type,
    name,
    hasError} = props;

  const checkClassName = classnames(bsPrefix, 'ctr-check');
  const checkMarkClassName = classnames(
    'ctr-check__checkmark',
    `ctr-check__checkmark--${type}`,
    {'ctr-check__checkmark--error': hasError}
  );

  return (
    <Form.Check
      type={type}
      id={id}
      bsPrefix={checkClassName}>
      <Form.Check.Input
        value={value}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        name={name}
        disabled={disabled}
        bsPrefix='ctr-check__input'
        type={type}/>
      <Form.Check.Label bsPrefix='ctr-check__label'>
        <span className={checkMarkClassName}/>
        <p className='ctr-check__text'>{label}</p>
      </Form.Check.Label>
    </Form.Check>
  );
};

export default React.memo(Check);
