import * as React from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import {FocusEventHandler} from 'react-select/lib/types';
import './SelectCustom.less';

interface Props {
  isDisabled?: boolean;
  value?: any;
  name?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  options: any;
  onChange: any;
  placeholder?: string;
  hasError?: boolean;
  hasMore?: boolean;
  loadMore?: () => any;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
}

const SelectCustom: React.FunctionComponent<Props> = (props) => {

  const {
    isDisabled, value, isMulti,
    isClearable, options, onChange, name,
    onBlur, onFocus, placeholder, hasError, loadMore, hasMore
  } = props;

  React.useEffect(() => {
    if (loadMore) {
      loadMore();
    }
  }, [options]);

  const getSelectStyle = () => {
    const tutkariBlue = '#2490c9';
    const tutkariDarkBlue = '#0a55a3';
    const lightBlue = '#e8f5fb';
    const fontColor = '#777777';
    const brightOrange = '#ff5800';
    const white = 'white';

    const getOptionsBackGroundColor = (provided, state) => {
      const {isSelected, isFocused} = state;

      if (isSelected || isFocused) {
        return tutkariBlue;
      }

      return provided.backgroundColor;
    };

    const getOptionsColor = (_provided, state) => {

      const {isSelected, isFocused} = state;

      if (isSelected || isFocused) {
        return white;
      }

      return fontColor;
    };

    const customStyle = {
      control: (provided) => ({
        ...provided,
        borderColor: hasError ? brightOrange : tutkariBlue,
        boxShadow: 'none',
        outlineColor: tutkariDarkBlue,
        ':hover': {
          ...provided[':hover'],
          borderColor: hasError ? brightOrange : tutkariDarkBlue
        }
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: tutkariBlue
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: tutkariBlue
      }),
      singleValue: (provided) => ({
        ...provided,
        color: fontColor
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: getOptionsBackGroundColor(provided, state),
        color: getOptionsColor(provided, state),
        ':hover': {
          ...provided[':hover'],
          backgroundColor: tutkariBlue,
          color: white
        }
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: 0
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0
      }),
      multiValue: (provided) => {
        return {
          ...provided,
          backgroundColor: lightBlue
        };
      },
      multiValueLabel: (provided) => ({
        ...provided,
        color: tutkariDarkBlue
      })
    };

    return customStyle;
  };

  const formatOptionLabel = (option) => (
    <div className='option-label-container'>
      <div>{option.label}</div>
      {
        option.message && <div className='option-label-message'>
          {option.message}
        </div>
      }
    </div>
  );

  const loadOptions = () => {
    return {
      options,
      hasMore
    };
  };

  return (
    <AsyncPaginate
      className={'ctr-select'}
      isDisabled={isDisabled}
      name={name}
      value={value}
      isMulti={isMulti}
      isClearable={isClearable}
      loadOptions={loadOptions}
      formatOptionLabel={formatOptionLabel}
      isOptionDisabled={(option) => option.disabled === true}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      styles={getSelectStyle()}
      placeholder={placeholder}
    />
  );
};

export default SelectCustom;
